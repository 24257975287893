<span class="wrapper" class="{{privacyLevel}}">
    <button [matMenuTriggerFor]="privacyLevels" [disabled]="asset.access < 8"
        (menuClosed)="isMenuOpen = false" (menuOpened)="isMenuOpen = true"
        [class.isOpen]="isMenuOpen" [class.isUpdating]="isUpdating"><img src="./assets/img/privacy-{{privacyLevel}}.svg"><span>{{capitalize(privacyLevel)}}</span></button>
</span>
<mat-menu #privacyLevels="matMenu" class="privacy-options">
    <button mat-menu-item (click)="setPrivacyLevel('inherited')"><img class="menu-icon" src="./assets/img/privacy-inherited.svg" /><span>Public/Inherited</span></button>
    <button mat-menu-item (click)="setPrivacyLevel('restricted')"><img class="menu-icon" src="./assets/img/privacy-restricted.svg" /><span>Restricted</span></button>
    <button *ngIf="user.isDynatraceUser" mat-menu-item (click)="setPrivacyLevel('dynatrace')"><img class="menu-icon" src="./assets/img/privacy-dynatrace.svg" /><span>Dynatrace Only</span></button>
</mat-menu>
