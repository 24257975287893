<div class="header">Edit Topic</div>

<div class="content">
    <mat-form-field>
        <mat-label>Topic identifier</mat-label>
        <input
            #_
            matInput
            [value]="note.description"
            (keyup)="note.description = _.value; checkDescription()"
            (change)="note.description = _.value; checkDescription()"
            (cut)="note.description = _.value; checkDescription()"
            (paste)="note.description = _.value; checkDescription()"
            type="text"
        >
        @if (!noteHasKnownId) {
            <mat-hint>This ID was not found in the description</mat-hint>
        }
    </mat-form-field>

    <ngx-stackedit
        [(value)]="note.data"
        mode="edit"
        showPreview="off"
        [allowImageUpload]="false"
        showToc="off"
    />
</div>


<div class="controls">
    <div style="flex: 1"></div>
    <button mat-flat-button (click)="save()">Save</button>
    <button mat-flat-button (click)="dialogRef.close()">Cancel</button>
</div>
