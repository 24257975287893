import { Injectable } from '@angular/core';
import { ConsoleLogger, LogIcon } from '@dotglitch/ngx-common';
import { Observable, Subject, forkJoin, merge, mergeAll } from 'rxjs';

import { DialogService } from 'src/app/services/dialog.service';
import { Fetch } from 'src/app/services/fetch.service';
import { DTOClassNameList, DTOClassNameToUriPath, EDTIDToDTOClassName } from 'src/dto/constants';
import { DTO, DTORef } from 'src/dto/dto';

const { log, warn, err } = ConsoleLogger("AssetCacheService", "#4db6ac");

const $cacheAge = Symbol("CacheAge");

const dtoRefMap = {
    coveragegoal: "coverage-goal",
    coverageobjective: "coverage-objective",
    coveragetask: "coverage-task",
    dynatracecluster: "dynatrace-cluster",
    dynatraceenvironment: "dynatrace-environment",
    dynatraceevent: "dynatrace-event",
    observabilityclaim: "observability-claim",
    observabilityallocation: "observability-allocation",
    logicalenvironment: "logical-environment",
}

@Injectable({
    providedIn: 'root'
})
export class AssetCacheService {

    // Entries are stored by keys formatted as "Project_v1.123456"
    private cache = new Map<string, DTO>();
    private watchers: { [key: string]: Subject<{
        action: "CREATE" | "UPDATE" | "DELETE"
        asset: DTO
    }> } = {};

    constructor(
        private readonly dialog: DialogService,
        private readonly fetch: Fetch
    ) {
        window.assetCache = this;
        this.startWatchers();
    }

    private startWatchers() {
        DTOClassNameList.forEach(dto => {
            this.watchers[dto] = new Subject();
        })
    }

    // Watch specific DTO(s) for changes
    watch(dto: string | string[]) {
        if (!Array.isArray(dto)) dto = [dto];

        // Resolve all of the underlying observables for the chosen DTOs.
        const subscriptions = dto.map(d => this.watchers[d]);

        // Merge the subscriptions into one observable
        return merge(subscriptions);
        // return {
        //     subscribe: (cb) => {
        //         // ! This isn't deprecated. The typedoc is bad.
        //             .subscribe(cb);

        //         const _unsubscribe = subscription.unsubscribe;
        //         subscription.unsubscribe = () => {
        //             _unsubscribe();
        //         }
        //     }
        // }
    }

    /**
     * Get an asset by it's EDTCODE, or dtoID
     * e.g.
     * `EDTTSK-000001` | `Task_v1.1` | `task.1`
     * @param identifier
     */
    async getAsset(identifier: string): Promise<DTO | null>
    /**
     * Get an asset from it's DTORef.
     * @param identifier
     */
    async getAsset(identifier: DTORef): Promise<DTO | null>
    /**
     * Get an asset by the provided id & DTO classname.
     * @param identifier
     */
    async getAsset(identifier: number, dto: string): Promise<DTO | null>
    /**
     * @hidden
     */
    async getAsset(identifier: number | string | DTORef, dto?: string) {
        try {
            const { url, cacheKey } = this.getAssetKeyAndEndpoint(identifier, dto);
            return this.cache.get(cacheKey) || this.fetch.get(url);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }

    /**
     * Check if a given asset is already stored in the cache
     * Will _not_ download an asset that isn't found in the cache
     * @param identifier
     * @param dto
     * @returns
     */
    getAssetFromCache(identifier: number | string | DTORef, dto?: string) {
        try {
            const { url, cacheKey } = this.getAssetKeyAndEndpoint(identifier, dto);
            return this.cache.get(cacheKey);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }

    private getAssetKeyAndEndpoint(identifier: number | string | DTORef, dto?: string) {
        let url: string;
        let cacheKey: string;

        // If we have a number, check for the provided dto.
        if (typeof identifier == "number") {
            if (typeof dto != 'string' || !/^[A-Z][a-z]+_?[vV]1\.\d+$/.test(dto)) {
                throw new Error("Cannot fetch asset with invalid DTO: " + dto + " and classname: " + identifier);
            }

            // TODO: Handle different DTO formats
            const pathSeg = EDTIDToDTOClassName[dto] ?? DTOClassNameToUriPath(dto);
            url = `/api/eda/v1.0/${pathSeg}/v1.0/${identifier}`;
            cacheKey = dto.toLowerCase().replace("_v1", '') + '.' + identifier;
        }
        // If the identifier is a string, check the format
        else if (typeof identifier == "string") {
            // EDTTSK-012345
            if (/^EDT[A-Z]{3}\-?\d{6}$/.test(identifier)) {
                const edtIDClassName = identifier.match(/^EDT[A-Z]{3}/)?.[0];
                const id = parseInt(identifier.match(/\d+$/)?.[0]);
                url = `/api/eda/v1.0/${EDTIDToDTOClassName[edtIDClassName]}/v1.0/${identifier}`;
                cacheKey = edtIDClassName.toLowerCase().replace("_v1", '') + '.' + id;
            }
            // Application_v1.12345
            else if (/^[A-Z][a-z]+_?[vV]1\.\d+$/.test(identifier)) {
                const [edtClassName, id] = identifier.split('.');
                url = `/api/eda/v1.0/${DTOClassNameToUriPath(edtClassName)}/v1.0/${id}`;
                cacheKey = edtClassName.toLowerCase().replace("_v1", '') + '.' + id;
            }
            else if (/^[a-z_-]+\.\d+$/.test(identifier)) {
                const [edtClassName, id] = identifier.split('.');
                url = `/api/eda/v1.0/${DTOClassNameToUriPath(edtClassName)}/v1.0/${id}`;
                cacheKey = edtClassName.toLowerCase().replace("_v1", '') + '.' + id;
            }
            else {
                throw new Error("Cannot fetch string asset with invalid DTO identifier: " + identifier);
            }
        }
        // If the identifier is an object, check that it's a valid format
        else if (typeof identifier == "object") {
            if (typeof identifier.id != "number") {
                throw new Error("Cannot fetch object asset with invalid DTO identifier: " + identifier);
            }
            // const edtCode = identifier.edtCode ?? identifier['edtcode'];
            // if (typeof edtCode == "string") {
            //     url = `/api/eda/v1.0/${EDTIDToDTOClassName[edtCode]}/v1.0/${identifier.id}`;
            //     cacheKey = identifier.dto + '.' + identifier.id;
            // }
            // else
            if (typeof identifier.dto == "string") {
                url = `/api/eda/v1.0/${DTOClassNameToUriPath(identifier.dto)}/v1.0/${identifier.id}`;
                cacheKey = identifier.dto.toLowerCase().replace("_v1", '') + '.' + identifier.id;
            }
        }

        if (!url) {
            throw new Error("Cannot fetch asset with invalid DTO identifier: " + identifier);
        }

        return { url, cacheKey }
    }

    /**
     * Store or update an asset in the cache.
     * @param asset
     */
    storeAsset(asset: DTO) {
        if (!asset || Array.isArray(asset)) return;
        let dto = asset.dto?.toLowerCase().replace("_v1", '');
        dto = dtoRefMap[dto] || dto;

        if (!this.watchers[dto])
            debugger;

        this.cache.set(dto + '.' + asset.id, asset);
        this.watchers[dto].next({ action: "UPDATE", asset });
    }

    /**
     * Remove an asset from the cache.
     */
    removeAsset(asset: DTO) {
        if (!asset || Array.isArray(asset)) return;
        let dto = asset.dto?.toLowerCase().replace("_v1", '');
        dto = dtoRefMap[dto] || dto;

        if (!this.watchers[dto])
            debugger;

        this.cache.delete(dto + '.' + asset.id);
        this.watchers[dto].next({ action: "DELETE", asset });
    }
}
