import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoundEndpointAPI, DTO } from 'src/dto/dto';
import { MatMenuModule } from '@angular/material/menu';
import { DialogService } from 'src/app/services/dialog.service';

import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-security-control',
    templateUrl: './security-control.component.html',
    styleUrls: ['./security-control.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatMenuModule
    ]
})
export class SecurityControlComponent implements OnInit {
    private dtoAPI: BoundEndpointAPI;

    @Input() offline = false;

    private _asset: DTO;
    @Input()
    public set asset(value: DTO) {
        this._asset = value;
        if (!value) {
            this.privacyLevel = "public";
            this.accessLevel = "read-only";
            return;
        }

        this.dtoAPI = DTO.getEndpointAPI(value.dto);

        this.privacyLevel = value.privacy == 1 ? "dynatrace"
                          : value.privacy == 2 ? "restricted"
                          : value.parentId > 0 ? "inherited"
                          : "public";
        this.accessLevel  = value.access == 0  ? "revoke"
                          : value.access == 1  ? "read-only"
                          : value.access == 2  ? "limited"
                          : value.access == 4  ? "read-write"
                          : value.access == 8  ? "owner"
                          : value.access == 16 ? "admin"
                          : "unknown";
    }
    public get asset() {
        return this._asset;
    }
    public accessLevel  = "read-only";
    public privacyLevel = "inherited";
    public isMenuOpen = false;
    public isUpdating = false;

    constructor(private dialog: DialogService, public user: UserService) { }

    ngOnInit() { }

    async setPrivacyLevel(value: string) {
        const newPrivacy = value == "restricted" ? 2
                         : value == "dynatrace"  ? 1
                         : 0;

        if (this.asset.access < 8) {
            window.alert("You are not authorized to change the privacy setting for this asset.");
            return;
        }
        if (newPrivacy == 1 && !this.user.isDynatraceUser) {
            window.alert("You are not authorized to change the privacy setting to 'Dynatrace Only'.");
            return;
        }

        if (this.offline) {
            // Our 'container' will do the updating.
            this.asset.privacy = newPrivacy;
            this.privacyLevel  = this.asset.privacy == 1 ? "dynatrace"
                               : this.asset.privacy == 2 ? "restricted"
                               : this.asset.parentId > 0 ? "inherited"
                               : "public";
            return;
        }

        const message = newPrivacy == 1 && this.asset.privacy == 0
            ? `Changing the privacy level of this asset to 'Dynatrace Only' will affect its visibility for
            ALL items that are decendents of this asset. This means that unless they fit the access criteria,
            users that are currently able to see items will no longer be able to.`
            : newPrivacy == 0
            ? `Take EXTREME caution when changing the privacy level of this asset to 'Public' because EVERY
            item beneath this asset was created, updated and discussed under the assumption that they were
            protected under the current, more restrictive, privacy setting.`
            : `After setting this privacy level, users who previously had access to this asset will
            now only have access if they have an explicit assignment to this asset or one of its ancestors.`;

        const isConfirmed = await this.dialog.critical("WARNING!", message);
        if (!isConfirmed) return;  // The user did not confirm the change.

        this.isUpdating = true;
        this.asset.privacy = newPrivacy;
        this.privacyLevel = "(updating)";

        this.dtoAPI.patch(this.asset.id, {
            id: this.asset.id,
            privacy: this.asset.privacy
        })
        .then(asset => {
            // Just to confirm it visually.
            this.privacyLevel = asset.privacy == 1 ? "dynatrace"
                              : asset.privacy == 2 ? "restricted"
                              : asset.parentId > 0 ? "inherited"
                              : "public";
        })
        .catch(err => {
            console.error("Privacy update failed: " + err);
        })
        .finally(() => this.isUpdating = false);
    }

    onAccessLevel(evt: any) {
        // TODO: Get explanation for the access level this user has for this asset.
    }

    capitalize(word) { return word.charAt(0).toUpperCase() + word.slice(1); }
}


