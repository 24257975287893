<div class="header">
    <div class="title">
        <mat-icon>category</mat-icon>
        Contribution Notes
        <mat-icon style="margin-left: 4px;">help</mat-icon>
    </div>
</div>

<div class="notes">

    @for (note of notes; track note) {
        <div class="note">
            <div class="topic">{{note.description}}</div>
            <div class="author">{{note.createdBy}}</div>
            <div class="date">{{note.createdOn | date}}</div>
            <div class="content">{{note.data}}</div>

            @if (note.createdBy == user.email || user.hasElevateManagerRole) {
                <div class="controls">
                    <mat-icon (click)="deleteNote(note)">delete</mat-icon>
                    <mat-icon (click)="editNote(note)">edit</mat-icon>
                </div>
            }
        </div>
    }

    <div class="add-btn" mat-flat-button (click)="addNote()">
        <mat-icon>add</mat-icon>
        Add Topic
    </div>
</div>
